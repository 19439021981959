@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root{
  --primary-color: #FF8F31;
 
}
a {
  color: inherit;
  text-decoration: none;
}
body, html{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}
p {
  font-size: 15px;
}
.button {
  background: linear-gradient(180deg, #16747b, #24989f);
  width: max-content;
  padding: 5px 7px 5px 19px;
  border-radius: 40px;
  border: 3px solid #23959b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-weight: 300;
  box-shadow: 1px 6px 5px -2px #0003;
}
.button:before {
  content: "";
  position: absolute;
  width: 180px;
  height: 180px;
  left: 50%;
  top: 50%;
  background: linear-gradient(180deg, #0f6a70, #4abcc3);
  transform: translate(-50%, -50%) rotate(0deg);
  transition: 0.5s;
  /* z-index: -1; */
}

.button:hover:before {
  transform: translate(-50%, -50%) rotate(180deg);
}
.button .img {
  border: 3px solid #23959b;
  padding: 4px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
  box-shadow: 0px 3px 5px 0px #00000038;position: relative;
}
.button span{
  position: relative;
}
.button:hover {
  background: linear-gradient(10deg, #16747b, #24989f);
}
.button img{
  position: relative;
}
.header-blk {
  width: 95%;
  margin: auto;
}
.logo {
  margin-top: 23px;
}
.contact-tab {
  display: flex;
  width: 88%;
  justify-content: space-evenly;
  margin: auto;
  align-items: center;
}

.contact-tab .button {
  margin: 10px;
  font-size: 13px;
}

.button.padding {
  padding: 12px 17px;
}
.menu-tab{
  background:  url(https://webapi.entab.info/api/image/TAGOREFBD/public/Images/menu-bg.svg);
  background-size: 110% 110%;
    background-position: center;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    position: absolute;
    right: 30px;
    z-index: 999;
    top: 38px;
}
button.menu-toggle {
  transition: 0.5s;
  border: 0px;
  background: transparent;
}
.d-flex.justify-content {
  justify-content: center;
}
.menu-tab:hover {
  transform: rotate(90deg);
}
.menu-tab:hover button.menu-toggle {
  transform: rotateY(180deg) rotate(90deg);
}
.contact-tab .button.light {
  background: linear-gradient(10deg, #fe8e32, #eb8337);
  border: 3px solid #fe8e32;
}
.contact-tab .button.light:before{
  background: linear-gradient(10deg, #ffa357, #db6e1f);
  width: 130px;
    height: 130px;
}
.menu > div{
  position: fixed;
  background: #fff;
  width: 100%;
  min-height: 100vh;
  top: 0;
  right: 0;
  z-index: 99; 
}
.header {
  padding: 9px 0 8px;
  border-bottom: 5px solid #2499A0;
}
.pd-0{
  padding: 0;
}

.main-slider .owl-nav {
  position: absolute;
}

.main-slider button.owl-prev {
  position: absolute;
  left: -10px;
  background: linear-gradient(92deg, #ff000000, transparent) !important;
  top: 49%;
  transform: translate(-0%, -50%);
  width: 100px;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  transition: 0.5s;
}
.main-slider button.owl-next{
  position: absolute;
 
  background: linear-gradient(92deg, #ff000000, transparent) !important;
  top: 49%;
  transform: translate(-0%, -50%);
  width: 100px;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  transition: 0.5s;
  right: -10px;
}
.main-slider button.owl-prev:hover  {background: linear-gradient(92deg,  #2499a0a1, #2499a054,transparent) !important;}
.main-slider button.owl-next:hover {background: linear-gradient(92deg, transparent, #2499a054, #2499a0a1) !important;}

.main-slider .owl-nav {
  position: static;
  height: 100%;
  width: 100%;
  top: 0;
}

.main-slider button.owl-prev span, .main-slider button.owl-next span {
  color: #ffffff94;
  font-size: 4rem;
  line-height: 0px;
  border: 2px solid #ffffff94;;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  border-radius: 50%;
} 

.main-slider .slide-content {
    position: absolute;
    left: 10%;
    bottom: 14%;
    background: #0000006b;
    padding: 20px 20px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 14px;
}
.main-slider .slide-content h2 {
  font-size: 43px;
}
/* .main-slider .slide-content span{
  color: var(--primary-color)
} */
.main-slider .owl-dots{
  display: none;
}
.social-media {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9
}

.social-media img {
  width: 46px;
  margin: 4px 5px 0;
}
.highlihgt {
  background: linear-gradient(90deg, transparent, black,black,black, transparent);
  color: #fff;
  margin-top: -10px;
  padding: 5px;
  padding-bottom: 0;
  display: flex;
  padding-left: 180px;
  position: absolute;
  width: 100%;
  bottom: 9px;
  z-index: 9;
} 
 
.highlihgt .icon img {
  width: 44px;
  float: left;
  margin-top: -13px;
  z-index: 9;
  position: relative;
}
.highlihgt .icon {
  height: 29px;
}
.highlihgt p {
  display: inherit;
  margin: 0 20px;
}
.title{
  text-align: center;
  margin-bottom: 40px;
}
.title p{margin-bottom: 0;letter-spacing: 2px;color: #666666;}
.title h3{
  color: #333333;
  font-weight: 700;
}
.title h3 span{
  color: #333333;
  font-weight: 700;
  }
  .about-virtual{
    background:  url(https://webapi.entab.info/api/image/TAGOREFBD/public/Images/about-bg.png);
    padding: 20px;
    background-size: 100% 100%;
    position: relative;
    margin-top: 40px;
    color: #fff;
  }
  .about-virtual button {
    text-transform: uppercase;
    background: var(--primary-color);
    color: #fff;
    padding: 5px 14px;
    border-radius: 5px;
    border: 0px;
    transition: 0.5s;
}
.about-virtual button:hover {
  background: #1d868d;
  /* color: #000; */
}
.about-virtual .text h3 span {
  font-weight: 600;
}
.about-virtual .text h3 {
    margin: 30px 0;
    font-weight: 300;
}
  .text {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background: #00000070; */
    width: 97%;
    height: 81%;
    /* top: 0; */
    transform: translate(-50%, -50%);
    left: 50%;
    top: 45%;
}

.about-virtual video {
  width: 100%;
  height: 350px;
  object-fit: cover;
  margin-bottom: 20px;
  transition: 1s;
}
.about-virtual video.active{
  margin-bottom: 39px;
}
.about-virtual + div + div{ display: none;;}
video.active {
  height: 600px;
}

video.active + div {
  display: none;
}
video.active + div + button{
  display: block;

}
.about-virtual > button {
  position: absolute;
  right: 30px;
  top: 30px;
  display: none;
}
.about-us {
  text-align: center;
  padding: 50px 0;
  background:  url(https://webapi.entab.info/api/image/TAGOREFBD/public/Images/about-background.png); 
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.about-us .button {
  margin: auto;
  /* display: block; */
}
.facilities{
  background:  url(https://webapi.entab.info/api/image/TAGOREFBD/public/Images/facilities.png); 
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.facilities > div{
  width: 88%;
  margin: auto;
}
.facil-blk {
  text-align: center;
  margin-top: 30px;
}
.facil-blk p {
  color: #666666;
}

.facil-blk img {
  width: 71px;
}

.facil-blk h3 {
  font-size: 19px;
  margin: 20px 0;
}
.imp-link{
  margin: 100px 0;
}
.imp-link img {
  width: 50px;
  position: relative;
  z-index: 9;
}

.imp-link .d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imp-link .imp-img {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 30px;
  position: relative;
}

.imp-link .imp-img:after {
  content: "";
  width: 100%;
  height: 100%;
  border: 2px solid #187b82;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px; 
  background-clip: border-box;
  padding: 2px; /* Match the width of the border */
  box-sizing: border-box; /* Include padding in width and height */
  animation: 15s rotate infinite;
}
.contact-header {
  position: absolute;
  right: 110px;
  bottom: 14%;
  z-index: 9;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
}

.container-fluid.min-ht {
  position: relative;
}

.contact-header img {
  width: 39px;
  margin-right: 8px;
}

.contact-header a {
  display: block;
  /* color: #fff; */
}
.imp-link .imp-img:before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #FFA052, #EF8935);
  transform: rotate(45deg);
  transform-origin: center;
  position: absolute;
  border-radius: 10px;
  
  animation: 20s rotate infinite;
  z-index: 9;    
}
@keyframes rotate{
  0%{
      transform: rotate(0deg)
  }
  100%{
      transform: rotate(360deg)
  }
}
.imp-link h4 {
  color: #666666;
  width: 38%;
  font-weight: 600;
  font-size: 20px;
}
.mar-0{
  margin: 0
}
  .button.padding.arrow {
    background: no-repeat;
    border: none;
    box-shadow: none;
}
.button.padding.arrow:before {
  display: none;
}

.button.padding.arrow .img.mar-0 {
    background: linear-gradient(180deg, #16747b, #24989f);
    width: 50px;
    height: 50px;
    transition: 0.5s;
}
.button.padding.arrow .img:hover {
  /* margin-bottom: -5px; */
  box-shadow: 0px 3px 5px 0px #000000ab;
}
.news-event .title {
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.news-event .title.center >div:nth-child(1) {
  width: 90%;
  text-align: center;
  padding-left: 120px;
}

.news-event marquee .button.padding.arrow  .img{
  background: linear-gradient(10deg, #fe8e32, #eb8337);
  border: 3px solid #fe8e32;
  width: 40px;
  height: 40px;
  transition: 0.5s;
}
.news-event marquee .button.padding.arrow .img:hover {
  background: linear-gradient(120deg, #fe8e32, #eb8337);
  box-shadow: 1px 4px 17px -12px #000;
}

 
.newsarea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.newsarea > div {
  border-bottom: 1px solid #ddd;
  /* margin-bottom: 20px; */
  width: 100%;
  padding-bottom: 13px;
}

.newsarea  p {
  margin-bottom: 3px;
}
.evt-blk {
  border: 1px solid #F6D96F;
  border-radius: 11px;
  padding: 15px;
  text-align: center;
  background: #F6E7B1;position: relative;
  width: 95%;
}
.evt-blk p.date {
  width: max-content;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 20px;
  background: #00000057;
  padding: 8px 10px;
  border-radius: 9px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
  display: unset;
  font-size: 14px;
  line-height: 23px;
}

.evt-blk p.date span {
  display: block;
  font-size: 26px;
  letter-spacing: 0;
}

.readmore {
  width: max-content;
  background: linear-gradient(10deg, #16747b, #24989f);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 3px;
  color: #fff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  font-size: 13px;
  top: 34%;
  opacity: 0;
  transition: 0.5s;
}
.news-blk marquee {
  height: 335px;
}
.readmore img {
  width: 15px !important;
  margin-left: 14px;
}
.evt-title h3 {
  font-size: 17px;
  width: 100%;
  margin: 20px auto 10px;
  color: #333333;
}
.evt-blk:hover .readmore {opacity: 1;}
.news {
  background: #F5F5F5;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0px 43px 40px -55px #000;
}
.events {
  background: #F5F5F5;
  padding: 40px 20px;
  border-radius: 16px;
  width: 98%;
  margin-left: auto;
  box-shadow: 0px 43px 40px -55px #000;
}
.news-event > div {
  width: 88%;
  margin: auto;
}
.evt-img p, .evt-img h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:1;
  overflow: hidden;
}
.message-block {
  display: flex;
  align-items: flex-start;
}

.message-block .message-image {
  width: 12%;
  margin: 9px 0 9px 5px;
  border: 10px solid #fff;
  box-shadow: -2px 0px 5px -2px #bdbdbd;
} 
.message > div {
  width: 80%;
  margin: auto;
}
.message .title {
    text-align: right;
    text-transform: uppercase;
}
.message{
  background:  url(https://webapi.entab.info/api/image/TAGOREFBD/public/Images/message-bg.png) no-repeat;
  background-size: auto 75%;
  background-position: right bottom;
  padding: 130px 0 30px;
}

.message-content { 
  width: 88%;
  padding: 30px;
  box-shadow: 0px 43px 40px -55px #000, 0px 0px 5px 0px #e5e5e5;
  margin: 10px 0;
  margin-right: 5px;
  margin-bottom: 40px;
  background: #fff;
}
.message-contentdiv div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
img.quotes {
  position: absolute;
  right: 0;
  top: 0;
  width: 71px !important;
}
 

.princi-Name {
  width: max-content;
  position: relative;
  margin-bottom: 20px;
}

.princi-Name:after {
  background: linear-gradient(45deg, #FFA052, #EF8935);
  width: 100%;
  height: 6px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 9%;
  transform: translate(-0%, -50%);
}
.princi-Name h3 {
  font-weight: 600;
  font-size: 22px;
}
.princi-Name p {
  width: max-content;
  position: relative;
  margin-bottom: 0;
  background: #fff;
  z-index: 9;
  padding-right: 15px;
  color: #2499A0;
  letter-spacing: 4px;
}
.message-block .button.light.padding , .branch .button.light.padding {
    background: linear-gradient(10deg, #fe8e32, #eb8337);
    border: 0px solid #fe8e32;
    border-radius: 0 !important;
    padding: 4px 4px;
    position: absolute;
    z-index: 9;
    right: 60px;
    transition: 0.5s;
    text-transform: capitalize;
}
.message-block .button.light.padding,.branch .button.light.padding {
  padding: 9px 16px;
  bottom: -18px
}
.message-block .button.light.padding:before, .branch .branch-blk .button.light.padding:before {
  background: linear-gradient(180deg, #e17522, #ffae70);
}
.message-block .button.light.padding  img {
    width: 18px;
    margin-left: 8px;
    border-radius: 0 !important;
}

.gal-img .description {
  position: absolute;
  width: 90%;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 9;
  bottom: 0;
  flex-direction: column;
  padding: 20px;
  opacity: 0;
  transition: 0.5s;
  background: #000000cf;
  transform: rotate(310deg);
}

.gal-img:hover {
  transform: scale(1.3);
  z-index: 999;
  position: relative;
  display: block;
}

.gal-img .description button {
  padding: 5px 10px 3px;
  background: var(--main-color);
  color: #fff;
  border-radius: 9px;
  border: 1px solid #fff;
  font-size: 13px;

}

.gal-img:hover .description {
  opacity: 1;
  height: 90%;
  transform: translate(-50%, -50%) rotate(360deg);
  left: 50%;
  top: 50%;
  border-radius: 8px;
}

.gal-img {
  position: relative;
  transition: 0.5s;
  margin: 40px 0;
  width: 89%;
  overflow: hidden;
  border-radius: 9px;
  box-shadow: -1px 4px 5px 0px #0000003d;
}
.gal_new h4.catgry-name{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

}
.gal-content p{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

}
.gal-img .description h3 {
  margin: 0;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 2px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: center;
}

.gallery { 
  position: relative;
  background:  url(https://webapi.entab.info/api/image/TAGOREFBD/public/Images/gallery-bg.png);
  background-size: 100%;
  padding-bottom: 140px;
  
}
 
.gallery .news-event .title {
  width: 100%;
}
 

video.video_slider {
  width: 100%;
  max-height: 800px;
  object-fit: contain;
}

.gallery .owl-nav.disabled button {
  color: #fff !important;
  background: #026a901a !important;
  border: 1px solid var(--main-color) !important;
  color: var(--main-color) !important;
}

.gal-img .description img {
  width: 30px !important;
}
.gallery > div {
  width: 88%;
  margin: auto;
}
footer {
  background: linear-gradient(176deg, #2499a0, #167479);
  padding: 50px 0 10px;
}
.footer img {
  margin: auto;
  display: block;
}
.footer {
  color: #fff;
}

.quick-link ul {
  display: flex;
  flex-direction: column;
  height: 200px;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 0;
}

.quick-link ul li a {
  color: #fff;
  text-decoration: none;
}

.quick-link ul li {
  list-style: none;
  position: relative;
  padding-left: 20px;
  margin-bottom: 11px;
  font-size: 14px;
  font-weight: 300;
}

.quick-link ul li:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 7px solid #fff;
  border-left: 4px solid #fff0;
  border-right: 4px solid #fff0;
  position: absolute;
  left: 0;
  top: 7px;
  transform: rotate(-90deg);
}
.footer h3 {
  font-size: 20px;
  margin-bottom: 20px;
}
.branch .branch-blk {
  text-align: center;
  background: #fff;
  color: #333333;
  padding: 20px;
  z-index: 9;
  position: relative;
  width: 83%;
  margin-bottom: 40px;
  box-shadow: -16px 15px 14px -17px #00000091;
  margin-left: 15px;
  margin-top: 15px;
  border-radius: 10px;
}
.branch .branch-blk h4 {
  color: #1a7e83;
}

.branch i {
  display: block;
    color: #f58934;
    font-size: 20px;
}
.branch .button.light.padding img {
  width: 16px;
  margin-left: 10px;
}

.branch .branch-blk a {
  display: block;
}

.branch .branch-blk .button.light.padding {
  transform: translate(-50%, 46%);
  /* display: block; */
  margin: auto;
  bottom: 0;
  top: unset;
}
.branch {
  margin-top: -170px;
}
.branch .owl-nav {
  display: block;
  position: absolute;
  right: 70px;
  top: 20px;
  /* width: 20px; */
  /* height: 20px; */
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.branch .owl-nav button {
  width: 28px;
  height: 30px;
  background: #EE8535 !important;
  border-radius: 50% !important;
  font-size: 27px !important;
  line-height: 0 !important;
  box-shadow: inset 0px 0px 5px 0px #00000036;
}

.branch .branch-blk h4 {
  text-align: center;
  margin-bottom: 22px;
  padding-right: 70px;
  font-weight: 600;
}
.branch-blk h4+ p {
  font-size: 19px;
  font-weight: 300;
  color: #000;
}
.quick-link {
  border-right: 2px solid #ffffff87;
}

footer iframe {
  margin-top: 50px;
  height: 180px;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 0px #6c6c6c;
  border: 5px solid #fff;
}
.copyright p {
  margin: 0;
  text-align: center;
  background: #084548;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  font-weight: 300;
}

/* 
.menu { 
  list-style-type: none;
  padding: 0;
  position: fixed;
  bottom: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  z-index: 1;
  border: none;
  flex-direction: column;
  padding: 20px 20px;
  width: 100%; 
  box-shadow: -1px 1px 5px 0px #000000a1;
  z-index: 99;
  height: 100%;
} */
.sub-submenu li:hover {
  color: var(--primary-color);
}
ul.submenu {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  margin-top: 60px;
}
a:hover{
  color: inherit;
}
ul.submenu > li {
  width: 25%;
  margin-bottom: 40px;
  list-style: none;
  text-transform: uppercase;
  color: #2499a0;
  font-weight: 600;
  font-size: 21px;
}

.menu .logo {
  width: 80%;
  margin: auto;
}

.menu .logo img {
  width: auto;
  margin: auto;
  display: block;
  margin: 40px auto;
}

ul.submenu > li ul {
  color: #333;
  padding: 0;
  list-style: none;
  margin-top: 20px;
}

ul.submenu > li ul li {
  margin-top: 9px;
  font-weight: normal;
  font-size: 14px;
}

ul.submenu li:hover {
  color: var(--primary-color);
}
  
.innerSection {
  padding: 50px 0 100px;
}
.breadcrumb {
  min-height: 250px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background:  url(https://webapi.entab.info/api/image/TAGOREFBD/public/Images/about.jpg); 
  position: relative;
  background-attachment: fixed;
  background-size: 100% auto;
  background-position: top;
}
.breadcrumb ul li {
  margin: 0 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}
.breadcrumb::before {
  content: "";
  width: 100%;
  height: 100%;
  background: #00000085;
  left: 0;
  top: 0;
  position: absolute;
}

.breadcrumb ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
}
 
.form-section {
  position: fixed;
  z-index: 9;
  background: #0000003b;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  z-index: 99;
}
.form-section.active {
  display: flex;
}
.form-section .form-block {
  background: #fff;
  padding: 30px;
  width: 600px;
  border: 4px solid #247f84;
  border-radius: 14px;
  box-shadow: 0px 0px 20px 8px #0000005c;
  max-width: 95%;
}

.form-heading {
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
  color: #208990;
}

.form-section .form-block .form-feilds label {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
}

.form-section .form-block .form-feilds {
  display: flex;
  flex-wrap: wrap;
}

.form-section .form-block .form-feilds > div {
  width: 50%;
  margin-bottom: 20px;
}

.form-section .form-block .form-feilds > div input, .form-section .form-block .form-feilds > div select, .form-section .form-block .form-feilds > div textarea {
  width: 97%;
  padding: 5px;
  margin-bottom: 0px; 
  border: 1px solid #ddd;
} 
p.success-message {
  color: green;
  text-align: center;
}
.message-feild {
  width: 100% !important;
}

.submit {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit input {
  width: max-content !important;
  margin: 20px;
  min-width: 100px;
  /* margin: auto; */
}
.form-section .form-block .form-feilds > div input[type="submit"] {
  background: #208990;
  color: #fff;
  border-radius: 5px;
  letter-spacing: 1px;
}
.close {
  float: right;
  font-weight: 800;
  font-size: 20px;
  margin-top: -11px;
}
.contact-blk {
  display: flex;
  /* align-items: center; */
  background: #2499a0;
  color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 19px 15px -20px #000;
  border-radius: 7px;
}

.contact-blk img {
  width: 40px;
}

.contact-blk .contact-info h6 {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #ffc799;
  font-size: 19px;
}

.contact-blk .contact-icon {
  margin-right: 20px;
}

.contact-blk .contact-info p {
  margin-bottom: 0;
}

.contact-blk .contact-icon {
  padding: 20px;
  background: #ededed;
  display: flex;
  align-items: center;
}

.contact-blk .contact-info {
  padding: 17px;
}
.innerSection iframe {
  border: 15px solid #fff;
  box-shadow: 0px 0px 5px #ddd;
}
.close, .online-reg {
  cursor: pointer;
}
.table-responsive table {
  width: 100%;
}

.table-responsive table td {
  padding: 9px;
  border: 1px solid #ddd;
}

.table-responsive table thead {
  background: #2499a0;
  color: #fff;
}

.table-responsive table thead th {
  font-weight: 400;
  padding: 9px;
  text-transform: uppercase;
}
p.error { 
  color: red;
  margin-bottom: 0;
}
.row.tabs-dynamic {
  background: #ebebeb;
  padding: 13px 0;
}

.row.tabs-dynamic p {
  margin-bottom: 0;
}

.row.tabs-dynamic input, .row.tabs-dynamic select {
  border: 1px solid #ddd;
  width: 100%;
  padding: 6px;
  border-radius: 5px;
}

.readmore img {
  width: auto !important;
  height: auto;
  background: transparent;
  display: inline-block;
}
.eventbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 13px;
  box-shadow: 0px 0px 5px 0px #ddd;
  margin-top: 20px;
  border-radius: 4px;
  overflow: hidden;
}

.eventbox .eventboximg {
  width: 27%;
}

.eventbox .eventboxdesc {
  width: 70%;
}

.eventbox .eventboximg img {
  width: 100%;
  height: 160px;
  object-fit: contain;
  background: #ebebeb;
}

.eventbox .eventdate {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.eventbox .eventdate i {
  margin-right: 5px;
  color: #2e949b;
}

.eventbox .eventattch {
  position: absolute;
  right: 0;
  top: 0;
  background: var(--primary-color);
  color: #fff;
  padding: 2px 6px;
}
.evt-img  img {
  height: 180px;
  object-fit: contain;
  width: 100%;
  background: #e9e9e9;
}
.innerSection .news-blk {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  box-shadow: 0px 0px 5px 0px #ddd;
}

.news-blk .news-head {
  width: 13%;
}

.news-blk .news-content {
  width: 84%;
}

.news-blk  .attachment {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--primary-color);
  color: #fff;
  padding: 3px 7px;
}

.news-blk .news-head {
  text-align: center;
  background: linear-gradient(176deg, #2499a0, #167479);
  color: #fff;
  padding: 27px 5px;
}

.news-blk .news-head span {
  display: block;
}

.news-blk .news-content h3 {
  font-size: 21px;
}

.news-blk .news-content p {
  margin-bottom: 0;
  font-size: 14px;
}

.news-blk .news-head h2 {
  font-size: 24px;
}

.gal_new {
  padding: 10px;
  box-shadow: 0px 0px 5px 0px #ddd;
  margin-top: 20px;
  position: relative;
}

.gal_new img.img-fluid {
  height: 210px;
  object-fit: contain;
  background: #dbdbdb;
}

.gal_new h4.catgry-name {
  font-size: 20px;
  margin-top: 20px;
} 

.gal_new .total-img {
  background: var(--primary-color);
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  padding:  5px 10px;
  box-shadow: 7px 5px 11px -10px #000;
}

.gal_new .total-img p {
  margin-bottom: 0;
} 

.gal_new h6.date {
  font-size: 14px;
}

.gal_new h6.date span {
  float: right;
  font-size: 20px;
}
.sub_gallery img{
  height: 200px;
  background: #ddd;
  object-fit: contain;
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 5px;
  width: 100%;
  margin-top: 20px;
} 

.discription {
    text-align: center;
    margin-top: 20px;
}

.discription h3.subhead {
    font-size: 22px;
    color: var(--primary-color);
}
.video-gallery a {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.video-gallery a i {
  font-size: 30px;
  margin: 20px;
  color: red;
}

.video-gallery {
  margin-top: 20px;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 5px;
}

.video-gallery a h3 {
  font-size: 18px;
}

.video-gallery a p {
  margin-bottom: 0;
  font-size: 13px;
}

.popup {
  padding-right: 0 !important;
}
.popup .carousel-control-next, .popup .carousel-control-prev { width: auto; }
.popup .carousel-control-next { right: -15px; }
.popup .modal-dialog { min-width:40%; transform: translateY(-100px); }
.carousel-item .modal-header { background: #0a4685; padding: 13px 0 0 }
.popup .modal-dialog img { width: 100%; object-fit: contain; }
.popup .carousel-item .modal-body h1 {padding-bottom: 0; text-align: center; font-size: 17px; color: #000; overflow: hidden; font-weight: 600; text-transform: capitalize; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 10px; line-height: 28px; }
.popup .carousel-item .modal-dialog .modal-header .btn-close { color: #fff; padding-right: 31px }
.popup .carousel-item .modalcard .card-body { padding: 10px }
.popup .carousel-item .modalcard .card-body p { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 35px; text-align: center; padding-top: 10px; line-height: 26px; }
.popup .carousel-item .modalcard { border: none }
.popup .modal-content { padding: 0 0 10px }
.popup .carousel-item button.btn-close:focus { box-shadow: none }
.popup .carousel-item a.btn.btn-primary.btn-sm { border-radius: 6px;
  box-shadow: -2px 14px 11px -9px #0000008a; 
  transition: .5s; background: linear-gradient(180deg, #0f6a70, #4abcc3); border: #0f6a70; margin: 0 10px; color: #fff!important ;     padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;}
  .popup .carousel-item a.btn.btn-primary.btn-sm:focus { box-shadow: none; border: 1px solid #0f6a70; }
  .popup .carousel-item a.btn.btn-primary.btn-sm:hover {
  background: linear-gradient(180deg, #0f6a70, #4abcc3);
}
.popup .carousel-item button.btn-close {position: absolute;z-index: 999;background-color: #0f6a70;right: 0;}
.popup .carousel-item .btn-close { background:  url('https://webapi.entab.info/api/image/TAGOREFBD/public/Images/closeicn.png') no-repeat; opacity: 1; padding: 10px; background-position: center; border-radius: 0; }
.popup .modal-bottom { display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 30px; }
 
.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

.innerSection .msgimg {
  border: 7px solid #f1f1f1;
  box-shadow: 0px 0px 17px -13px #000;
  margin-left: 20px;
  background: #ddd;
}
.about-img img {
  border: 10px solid #fff;
  box-shadow: 0px 0px 11px 0px #b4b4b4;
  margin-top: 30px;
}
.gal-img>img {
  height: 200px;
  object-fit: contain;
  background: #ddd;
}
.message-content{
  position: relative;
}
 
 
.cbsebtn {
  background: #219198;
  width: max-content;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  color: #fff;
}

.tcblock {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
} .tc {
  width: 49%;
  margin-bottom: 30px;
} .tcblock iframe {
  width: 49%;
  border: 0px solid #fff;
    box-shadow: 0px 0px 0px #ddd;
} .tc label {
  color: #000;
  width: 100%;
  margin-bottom: 0;
} .tc label p {
  margin-bottom: 0px;
} .tc .form-group {
  margin-bottom: 20px;
} .form-control:focus {
  box-shadow: none;
  border: 1px solid #ccc;
} a.viewdetailsbtn {
  color: #fff;
  font-size: 14px;
} .viewdetailsbtn {
  padding: 10px 15px;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  border: none;
  background:linear-gradient(131deg, #22939a, #e88234);
  transition: all 0.2s ease-in;
} .viewdetailsbtn:hover {
  background:linear-gradient(131deg, #e88234, #22939a);
}
a.view-more {
  cursor: pointer;
}
.gal-img .dynamicgalimg {
  width: 100%;
  height: 150px;
  object-fit: contain;
  background: #f1f1f1;
}
@media(max-width: 1600px){
  .main-slider .slide-content h2 {
    font-size: 33px;
}
.facilities > div, .news-event > div, .gallery > div{
  width: 95%;
}
.branch .branch-blk {
  width: 94%;
}
}
@media(max-width: 1400px){
  .main-slider button.owl-prev span, .main-slider button.owl-next span { 
    width: 70px;
    height: 70px; 
    padding-bottom: 6px; 
}
  .button {
    padding: 2px 4px 2px 12px;
}
.online-reg img {
  width: 160px;
}
.button.padding {
  padding: 9px 14px;
}
.menu-tab { 
  top: 25px;
  right: 20px
  
}
.contact-tab { 
  width: 92%; 
}
  p {
    font-size: 14px;
}
.imp-link h4 { 
  font-size: 20px;
}
.news {
  background: #F5F5F5;
  padding: 40px 30px;
  border-radius: 16px;
  box-shadow: 0px 43px 40px -55px #000;
}


 
.footer img {
  width: 170px;
  margin: auto;
  display: block;
}
.branch .branch-blk p {
  font-size: 13px;
}
.branch .branch-blk .button.light.padding { 
  right: 20px;
}

}
@media(max-width: 1200px){
  .row.tabs-dynamic input, .row.tabs-dynamic select {
    border: 1px solid #ddd;
    width: 100%;
    padding: 6px;
    border-radius: 5px;
    margin-top: 10px;
}
  .logo img.img-fluid {
    margin: 20px auto;
    display: block;
}
  .message > div {
    width: 95%;
    margin: auto;
}
  .quick-link ul { 
    height: 100px; 
  }
  .branch {
    margin-top: 0;
}
.header-blk {
  width: 98%;
  margin: auto;
}
.button { 
  padding: 2px 7px 2px 8px; 
}
.main-slider .slide-content h2 {
  font-size: 26px;
}
.contact-header{
  font-size: 13px;  
}
.contact-header img {
  width: 28px;
  margin-right: 8px;
}
.highlihgt { 
  padding-left: 100px;
}
.news { 
  padding: 20px 20px;  
}
.main-slider button.owl-prev span, .main-slider button.owl-next span {  
  width: 50px;
  height: 50px; 
  padding-bottom: 6px; 
}
}
 

@media(max-width: 991px){

  .menu ul.submenu { 
    width: 90%; 
}
.submenu >li{
  width: 32%
}
  .main-slider .slide-content h2 {
    font-size: 18px;
}
.events {
  width: 100%; 
}

.news {
  margin-bottom: 30px;
}
  .highlihgt {
    padding-left: 50px;
}
.contact-header { 
  right: 40px; 
}
.imp-link .imp-img {
  width: 70px;
  height: 70px; 
}
.imp-link img {
  width: 33px; 
}
.imp-link h4 {
  font-size: 14px;
}
}
@media(max-width: 767px){
  .highlihgt {
    background: #000;
    position: static; 
    margin: 0;
}
.quick-link{
  border-right: 0px;
}
  .gallery {   
    
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .facilities, .about-us{
    
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .contact-tab {
    width: 100%;
    flex-wrap: wrap;
}
.logo img.img-fluid {
  margin: 20px auto;
  display: block;
  width: 74%;
}
  .imp-link .d-flex {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.imp-link {
  margin: 40px 0;
}
  .quick-link ul {
    height: 200px;
    border-right: 0px;
}
.message .row {
  flex-direction: column-reverse;
}
.message .title {
  text-align: center;
}
}
@media(max-width : 600px){
  video.active {
    height: 250px;
}
  .news-blk .news-head {
    width: 100%;
    margin-bottom: 20px;
    padding: 5px;
}

.news-blk .news-content {
    width: 100%;
}

.news-blk {
    flex-wrap: wrap;
    padding: 7px;
}

.news-blk .news-head span {
    display: unset;
}

.news-blk .news-head h2 {
    margin-bottom: 0;
}
  .eventbox {
    flex-wrap: wrap;
}

.eventbox .eventboximg {
    width: 100%;
    margin-bottom: 20px;
}

.eventbox .eventboxdesc {
    width: 100%;
}
  .form-section .form-block .form-feilds > div {
    width: 100%;
}
  .contact-tab .button {
    margin: 10px  0px;
    font-size: 13px;
}
.button .img { 
  margin-left: 6px; 
}
.online-reg img {
  width: 133px;
}
  .logo img.img-fluid {
    margin: 0px 0 10px;
    display: block;
    width: 81%;
}
  .submenu >li {
    width: 50%;
}
.menu .logo {
  width: 100% !important;}
  .message-block .message-image {
    width: 100%;
}
.contact-header {
  right: 40px;
  display: none;
}

.message-block {
    flex-direction: column;
}

.message-content {
    width: 100%;
}
.message{
  background: unset;
}
.main-slider .owl-nav, .main-slider .slide-content{
  display: none !important;
} 
.highlihgt {
  padding-left: 10px;
}
}
@media(max-width: 400px){
  .submenu >li {
    width: 100%;
}
  .quick-link ul {
    height: auto;
    border-right: 0px;
}
}

